<template>
  <div>
    <list
      ref="refHabilityList"
      sort-by="HabilidadId"
      :refetch-records="fetchHabilities"
      refetch-records-name="habilidades"
      :actions="actions"
      key-field="habilidadId"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteHability"
      :filters.sync="filters"
    />
  </div>
</template>
<script>

import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import humanResources from '@/services/humanresources.service'

export default {
  components: {
    List,
  },
  setup() {
    /* Refs */
    const refHabilityList = ref(null)
    /* Services */
    const {
      fetchHabilities,
      deleteHability,
    } = humanResources()
    /* Data */
    const tableColumns = [
      {
        label: i18n.t('humanResources.hability.columns.name'),
        key: 'nombre',
      },
      {
        label: i18n.t('humanResources.hability.columns.type'),
        key: 'habilidadTipo.nombre',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'create',
        aclResource: 'Habilidad',
        routeName: 'apps-human-resources-hability-edit',
        params: ['habilidadId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'Habilidad',
        params: ['habilidadId'],
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        routeName: 'apps-human-resources-hability-add',
        label: i18n.t('Routes.HabilityAdd'),
        aclAction: 'create',
        aclResource: 'Habilidad',
        icon: 'PlusCircleIcon',
      },
    ])
    const filters = ref([])
    return {
      refHabilityList,
      tableColumns,
      tableActions,
      actions,
      filters,
      fetchHabilities,
      deleteHability,
    }
  },
}
</script>
